ul.breadcrumb {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: $large-font;

  li {
    display: inline;

    & + li::before {
      padding: 0 0.5rem 0 0.25rem;
      color: black;
      content: ">";
    }

    a {
      color: $light-blue;
      font-weight: 500;
    }
  }
}
