footer {
  background: $primary-blue;
  color: $white;

  &.section {
    padding: 4rem 0 2rem;
  }

  h2 {
    color: $white;
  }

  a {
    color: $blue-2;
  }

  .content-wrapper {
    font-size: $large-font;

    @include media-breakpoint-down($medium-screen) {
      font-size: $small-font;
    }

    dl {
      display: grid;
      grid-template-columns: max-content auto;
      grid-row-gap: 0.75rem;

      @include media-breakpoint-down($small-screen) {
        display: flex;
        flex-wrap: wrap;
        grid-row-gap: $small-gap;
      }
    }

    dt {
      grid-column-start: 1;

      @include media-breakpoint-down($small-screen) {
        width: 180px;
      }
    }

    dd {
      grid-column-start: 2;

      @include media-breakpoint-down($small-screen) {
        width: 200px;
        margin-left: $general-gap;
      }
    }
  }

  .footer-note {
    margin-top: 6.5rem;
    text-align: center;

    @include media-breakpoint-down($medium-screen) {
      margin-top: 5rem;
    }

    .copyright {
      margin-top: 1rem;
      font-size: $small-font;
      color: $light-blue-gray;
    }
  }
}
