$wrapper-side-gap: $large-gap;

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-size: $normal-font;
  font-family: Ubuntu, sans-serif;
  line-height: 1.3em;
  color: $black;

  @include media-breakpoint-down($medium-screen) {
    font-size: $small-font;
    line-height: 1.5em;

    &.sp-menu-displayed {
      overflow: hidden;
    }
  }
}

main {
  &.top-padded {
    padding-top: $header-height;
  }
}

.section {
  position: relative;
  padding: 4rem 0;

  @include media-breakpoint-down($medium-screen) {
    padding: 3rem 0;
  }
}

.wrapper {
  width: calc(100% - 2 * #{$wrapper-side-gap});
  max-width: $wrapper-width;
  margin: 0 auto;
}

p {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

a {
  color: $blue;
  text-decoration: none;
  cursor: pointer;

  @include button-link-transition;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1em;
  line-height: 1.2em;
  color: $primary-blue;

  @include media-breakpoint-down($medium-screen) {
    @include medium-screen-extra-side-margin;
  }
}

h1 {
  font-size: $xxlarge-font;
}

h2 {
  font-size: $xlarge-font;

  &.large {
    font-size: $xxlarge-font;
  }
}

h3 {
  font-size: 1.25rem;
}

button,
.button {
  display: inline-block;
  padding: 0.75rem 2rem;
  font-size: $large-font;
  font-weight: 500;
  background-color: $blue;
  border-radius: 50px;
  box-sizing: border-box;
  text-align: center;
  color: $white;

  &.button--full-width {
    width: 100%;
  }

  @include media-breakpoint-down($medium-screen) {
    font-size: $normal-font;
  }
}

.social-media-link {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.25rem;
  background: no-repeat center / contain;

  &#{&}--linkedin {
    background-image: url("../images/icon-linkedin.svg");
  }

  &#{&}--facebook {
    background-image: url("../images/icon-facebook.svg");
  }
}

.display {
  &--large-screen-and-above {
    display: block;

    @include media-breakpoint-down($medium-screen) {
      display: none;
    }
  }

  &--medium-screen-and-below {
    display: none;

    @include media-breakpoint-down($medium-screen) {
      display: block;
    }
  }
}
