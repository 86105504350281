@use "sass:map";

$sp-menu-clicker-icon-width: 1.375rem; // 22px
%sp-menu-clicker-style {
  width: $sp-menu-clicker-icon-width;
  height: 2px; // NOTE: use px for super small measurement
  background-color: $primary-blue;
}

.header {
  height: $header-height;
  width: 100%;
  border-bottom: 1px solid $light-gray;
  background: $white;

  @include media-breakpoint-down($medium-screen) {
    box-shadow: $box-shadow;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: inline-flex;
    height: $header-height;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }

  &__menu-clicker {
    position: relative;
    display: none;
    height: $header-height;
    width: $header-height;
    margin-right: -$large-gap;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down($medium-screen) {
      display: inline-flex;
    }

    > i {
      position: relative;

      @extend %sp-menu-clicker-style;

      &::before,
      &::after {
        position: absolute;
        content: "";
        transition: all $transition-time / 2;

        @extend %sp-menu-clicker-style;
      }

      &::before {
        top: -$xsmall-gap;
      }

      &::after {
        top: $xsmall-gap;
      }
    }

    @at-root .sp-menu-displayed & {
      > i {
        background-color: transparent;

        &::before,
        &::after {
          top: 0;
          transform-origin: center;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &#{&}--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &#{&}--dark {
    background: $blue;

    .header__menu-clicker {
      > i {
        background-color: $white;

        &::before,
        &::after {
          background-color: $white;
        }
      }

      @at-root .sp-menu-displayed & {
        > i {
          background-color: transparent;
        }
      }
    }
  }
}

nav {
  @include media-breakpoint-down($medium-screen) {
    display: flex;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    height: $header-height;
    align-items: center;

    @include media-breakpoint-down($medium-screen) {
      height: auto;
      margin: auto;
    }

    > li {
      display: block;
      height: $header-height;

      a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1.5rem;
        color: $black;
        font-size: $large-font;
        text-align: center;

        @include media-breakpoint-down($large-screen) {
          padding: 0 1rem;
          font-size: $normal-font;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      &.current {
        a {
          font-weight: 500;
        }
      }

      &.hiring {
        a {
          color: $light-blue;
          font-weight: 500;

          .header--dark & {
            color: $white;
          }
        }
      }
    }
  }

  @include media-breakpoint-down($medium-screen) {
    display: none;
    position: fixed;
    top: $header-height;
    left: 0;
    width: 100%;
    height: calc(100% - #{$header-height});
    border-top: 1px solid $light-gray;
    background: $white;
    overflow: auto;
    z-index: map.get($z-index-values, "navigation");

    ul {
      width: 100%;
      flex-wrap: wrap;

      > li {
        width: 100%;

        a {
          width: 100%;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
        }
      }
    }

    @at-root .sp-menu-displayed & {
      display: flex;
    }
  }

  @at-root .header--dark & {
    background: $blue;
  }
}
